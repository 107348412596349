import React, { Component } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { security } from 'images/'
import Layout from '../../layouts'

class SecurityPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="faq animated fadeInPage">

          <Helmet>
            <title>Informatie over veiligheid bij Employes</title>
            <meta name="description" content="Lees meer over de maatregelen omtrent beveiliging bij Employes." />
            <meta itemprop="name" content="Informatie over veiligheid bij Employes" />
            <meta itemprop="description" content="Lees meer over de maatregelen omtrent beveiliging bij Employes."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="header padding-xxxl">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-7">
                  <h1>Informatie over veiligheid bij Employes</h1>
                  <p className="streamer">Employes biedt een volledig platform voor het beheren van alle personeelsgegevens. Lees hieronder welke maatregelen we treffen op het vlak van beveiliging van deze data.</p>
                </div>

                <div className="hero-illustration">
                  <img src={security} alt="hero illustratie faq"/>
                </div>
              </div>
            </div>
          </header>

          <section className="overview padding-xxl">
            <div className="container-md">
              <div className="grid yg center">
                <div className="col-12">
                  <h3>Data-opslag & backupbeleid</h3>
                  <p className="margin-m-bottom">Ons platform is gebouwd op het Amazon Web Services (AWS) platform. Door gebruik te maken van de cloud-native technologieën van AWS - ontworpen voor hoge veiligheid - leveren we een snelle en veilige ervaring voor alle gebruikers van ons platform. De data-opslag vindt alleen plaats in datacenters binnen Europa (Frankfurt en Ierland).
                  Wij hanteren automatische backuppolicies die ervoor zorgen dat jouw data te allen tijde behouden blijft. De data wordt eenmaal per dag veiliggesteld en behouden we voor een periode van 30 dagen. We maken hierbij gebruik van data-opslag op verschillende locaties om te voorkomen dat we afhankelijk zijn van één locatie.</p>

                  <h3>Identiteits- en toegangsbeheer</h3>
                  <p className="margin-m-bottom">Om te zorgen voor een hoge mate van veiligheid voor het toegangsbeheer tot Employes, bieden wij de mogelijkheid aan voor tweestapsverificatie. Iedere gebruiker kan hiermee zijn of haar account voorzien van een extra veiligheidslaag. Tevens hanteren wij een streng wachtwoordbeleid inclusief brute-force beveiliging waardoor het automatisch achterhalen van een wachtwoord onmogelijk wordt gemaakt. In Employes wordt iedere gebruiker er actief op gewezen om tweestapsverificatie in te schakelen. Daarnaast bieden wij ook de mogelijkheid aan om in te loggen middels single sign on (SSO) van Google.</p>

                  <h3>AVG</h3>
                  <p className="margin-m-bottom">Bij Employes voldoe je aan de regels die gelden voor de verwerking van persoonsgegevens op basis van de Algemene verordening gegevensbescherming. Daarnaast houden wij uiteraard ook rekening met de bewaartermijnen van de Belastingdienst. Meer informatie over de wijze waarop wij gegevens verwerken, kun je lezen in onze <a href="https://s3-eu-west-1.amazonaws.com/files-public.employes.nl/Overeenkomst_verwerking_persoonsgegevens_employes.pdf">verwerksovereenkomst</a>. Van de partijen waar wij mee werken, evalueren we de veiligheid.</p>

                  <h3>Getest door derden</h3>
                  <p className="margin-m-bottom">Ons platform is third-party tested. Wij laten op regelmatige basis een penetratietest uitvoeren om onze veiligheid verder te waarborgen. Hiervoor werken we samen met een professionele partij op het gebied van cybersecurity.</p>

                  <h3>Encryptie en datatransport</h3>
                  <p className="margin-s-bottom">We maken gebruik van data-encryptie in onze databases. Voor wachtwoorden hanteren we bijvoorbeeld SHA256. Dit betekent dat de wachtwoorden die worden opgeslagen, onomkeerbaar worden gehasht, wat een extra beveiligingsniveau biedt. Hierdoor kunnen de oorspronkelijke wachtwoorden niet worden afgeleid of hersteld.</p>
                  <p className="margin-m-bottom">Daarnaast zorgen we voor data-encryptie tijdens het transport van gegevens. We maken gebruik van standaard HTTPS-certificering, met minimaal TLS 1.2. Hierdoor worden alle gegevens die tussen de gebruikers en onze server worden verzonden, versleuteld. Dit voorkomt dat kwaadwillende partijen de verzonden gegevens kunnen onderscheppen of lezen.</p>

                  <h3>Gebouwd voor veiligheid</h3>
                  <p className="margin-m-bottom">Onze ontwikkelaars werken volgens de hoogste standaarden en met de grootst mogelijke kennis van security aan het platform. Een geautomatiseerde CI/CD workflow zorgt ervoor dat alle code-wijzigingen door meerdere personen worden gecontroleerd, voordat ze in productie worden genomen.</p>

                  <h3>Meer informatie</h3>
                  <p className="margin-m-bottom">Wil je graag meer weten over hoe wij omgaan met jouw data, dan kun je onze verwerksovereenkomst bekijken. Verder bekijk je hier onze <Link to="/privacy-policy/">privacy policy</Link>. Wil je graag meer weten over onze veiligheidsstandaarden, stuur dan je mail naar support@employes.nl</p>
                </div>
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default SecurityPage
